import { AccountType, BonusType } from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import { useBetslipContext } from '../../../context/betslip.context'
import { useCommonContext } from '../../../context/common.context'
import { useSportsBettingSettings } from '../../../lib/content'
import { useUser, useUserAccounts, getCurrencyIdForUser } from '../../../lib/user'
import { BetTypes } from '../../../services/bet/bet.models'
import { formatMoney } from '../../../util/number'
import { getFormattedOddValue } from '../../../util/odds'
import Tag from '../../core/Tag'
import HelpText from '../../HelpText'

export const BetslipValues = () => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const sportsBettingSettings = useSportsBettingSettings()
  const { user } = useUser()
  const { mainAccount } = useUserAccounts()
  const { getCurrencyById, oddsFormat } = useCommonContext()
  const currency = getCurrencyById(getCurrencyIdForUser(user, mainAccount))
  const isFreeBet = betslipContext.account?.type === AccountType.FreeBet
  const hasCheckBetError = betslipContext?.checkBetSlipErrors?.length > 0
  const isOddBoosted =
    betslipContext.selectedTokenBonus?.bonusType === BonusType.BetBoostToken &&
    betslipContext.initialMaxOdd < betslipContext.maxOdd
  const isRiskFree = betslipContext.selectedTokenBonus?.bonusType === BonusType.RiskFreeBetToken
  const isFreeBetToken = betslipContext.selectedTokenBonus?.bonusType === BonusType.FreeBetToken

  return (
    <>
      {betslipContext.selectedOdds?.length > 0 && (
        <div className="betslip-values flex flex-col space-y-1 text-xs font-semibold">
          {betslipContext.betType === BetTypes.Single && betslipContext.betAmount > 0 && (
            <div className="betslip-values-betcount flex justify-between">
              <span>{t('BetSlip.betCount')}</span>
              <span>{betslipContext.betAmount}</span>
            </div>
          )}

          {betslipContext.stakeTax > 0 && (
            <div className="betslip-values-staketax flex justify-between">
              <span>{t('BetSlip.stakeTax')}</span>
              <span>{formatMoney(betslipContext.stakeTax, currency)}</span>
            </div>
          )}

          {betslipContext.stakeFee > 0 && (
            <div className="betslip-values-stakefee flex justify-between">
              <span>{t('BetSlip.stakeFee')}</span>
              <span>{formatMoney(betslipContext.stakeFee, currency)}</span>
            </div>
          )}

          {(betslipContext.totalStake > 0 || betslipContext.stakePerBet > 0) && (
            <div className="betslip-values-totalstake flex justify-between">
              <div className="flex space-x-2">
                <span>
                  {betslipContext.betType !== BetTypes.System ? t('BetSlip.totalStake') : t('BetSlip.stakePerBet')}
                </span>

                {isFreeBet && (
                  <Tag backgroundColor="bg-secondary" textColor="text-secondaryContrast" className="normal-case">
                    <HelpText
                      displayLabel
                      title={t('BetSlip.freebetHelpTextTitle')}
                      description={t('BetSlip.freebetHelpTextDescription')}
                    />
                  </Tag>
                )}

                {isRiskFree && (
                  <Tag backgroundColor="bg-[#64C446]" textColor="text-white" className="normal-case">
                    <HelpText
                      displayLabel
                      title={
                        t('BetSlip.riskFreeToken.title') +
                        (betslipContext.selectedTokenBonus?.blockedAmountMultiplier > 0
                          ? ` | ${betslipContext.selectedTokenBonus?.blockedAmountMultiplier}x ${t(
                              'BetSlip.bonusToken.blocked'
                            )}`
                          : '')
                      }
                      description={t('BetSlip.riskFreeToken.description')}
                    />
                  </Tag>
                )}

                {isFreeBetToken && (
                  <Tag backgroundColor="bg-[#64C446]" textColor="text-white" className="normal-case">
                    <HelpText
                      displayLabel
                      title={
                        t('BetSlip.freeBetToken.title') +
                        (betslipContext.selectedTokenBonus?.blockedAmountMultiplier > 0
                          ? ` | ${betslipContext.selectedTokenBonus?.blockedAmountMultiplier}x ${t(
                              'BetSlip.bonusToken.blocked'
                            )}`
                          : '')
                      }
                      description={t('BetSlip.freeBetToken.description')}
                    />
                  </Tag>
                )}

                {betslipContext.stakeBonus > 0 && (
                  <Tag backgroundColor="bg-[#64C446]" textColor="text-white" className="normal-case">
                    <HelpText
                      displayLabel
                      title={t('BetSlip.stakeBonus.title', {
                        amount: formatMoney(betslipContext.stakeBonus, currency),
                      })}
                      description={t('BetSlip.stakeBonus.description')}
                    />
                  </Tag>
                )}
              </div>

              <span>
                {betslipContext.betType !== BetTypes.System
                  ? formatMoney(betslipContext.totalStake, currency)
                  : formatMoney(betslipContext.stakePerBet, currency)}
              </span>
            </div>
          )}

          {!hasCheckBetError &&
            betslipContext.betType === BetTypes.Combi &&
            sportsBettingSettings?.oddsDisplay?.betSlip?.showTotalMaximumOdds &&
            betslipContext.maxOdd > 0 && (
              <div className="betslip-values-maxodd flex justify-between">
                <div className="flex space-x-2">
                  <span>{t('BetSlip.maxOdd')}</span>
                  {isOddBoosted && (
                    <Tag backgroundColor="bg-[#64C446]" textColor="text-white" className="normal-case">
                      <HelpText
                        displayLabel
                        title={
                          t('BetSlip.betBoostToken.title', {
                            blockedAmountMultiplier: betslipContext.selectedTokenBonus.blockedAmountMultiplier,
                          }) +
                          (betslipContext.selectedTokenBonus?.blockedAmountMultiplier > 0
                            ? ` | ${betslipContext.selectedTokenBonus?.blockedAmountMultiplier}x ${t(
                                'BetSlip.bonusToken.blocked'
                              )}`
                            : '')
                        }
                        description={t('BetSlip.betBoostToken.description')}
                      />
                    </Tag>
                  )}
                </div>

                <div className="flex shrink-0 space-x-1 items-center">
                  {isOddBoosted && (
                    <span className={'line-through'}>
                      {getFormattedOddValue(oddsFormat, betslipContext.initialMaxOdd, sportsBettingSettings?.oddsDisplay?.oddsFormat?.totalMaximumOddsPrecision)}
                    </span>
                  )}
                  <span className={isOddBoosted ? 'text-primary' : ''}>
                    {getFormattedOddValue(oddsFormat, betslipContext.maxOdd, sportsBettingSettings?.oddsDisplay?.oddsFormat?.totalMaximumOddsPrecision)}
                  </span>
                </div>
              </div>
            )}

          {betslipContext.gainTax > 0 && (
            <div className="betslip-values-gaintax flex justify-between">
              <span>{t('BetSlip.gainTax')}</span>
              <span>{formatMoney(betslipContext.gainTax, currency)}</span>
            </div>
          )}

          {!hasCheckBetError && betslipContext.maxReturn > 0 && (
            <div className={`betslip-values-maxreturn flex justify-between font-semibold text-lg mt-2 ${betslipContext.stakeSelectorActive ? 'opacity-50' : ''}`}>
              <div className="flex items-center space-x-2">
                <span>{t('BetSlip.potentialWin')}</span>
                {betslipContext.gainBonus > 0 && (
                  <Tag backgroundColor="bg-[#64C446]" textColor="text-white" className="normal-case">
                    <HelpText
                      displayLabel
                      title={t('BetSlip.gainBonus.title', {
                        amount: formatMoney(betslipContext.gainBonus, currency),
                      })}
                      description={t('BetSlip.gainBonus.description')}
                    />
                  </Tag>
                )}
              </div>
              <span>{formatMoney(betslipContext.maxReturn, currency)}</span>
            </div>
          )}
        </div>
      )}
    </>
  )
}
