import { AnimatePresence, motion, useReducedMotion } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, BetslipAnimation, FadeInOut } from '../../../animations/common'
import { BetslipState, useBetslipContext } from '../../../context/betslip.context'
import { ContainedButton, IconButton } from '../../core/Button'
import {
  faArrowUpFromBracket,
  faChevronDown,
  faChevronUp,
  faLink,
  faTimes,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import { isAnonymousUser, isLoggedInCustomer, useUser } from '../../../lib/user'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { ClipLoader } from 'react-spinners'
import { setBodyNotScrollable, setBodyScrollable } from '../../../util/toggleBodyScrollable'
import { AccountType, BonusType } from '@arland-bmnext/api-data'
import Lottie from 'react-lottie'
import success from '../../../animations/lottie/success.json'
import OverlayContainer from '../../core/OverlayContainer'
import { useRouter } from 'next/router'
import { useSportsBettingSettings } from '../../../lib/content'
import Trans from 'next-translate/Trans'
import Tag from '../../core/Tag'
import AlertContainer from '../../core/AlertContainer'
import { BetTypes } from '../../../services/bet/bet.models'
import { BetslipItems } from './BetslipItems'
import { BetSlipSystemSelector } from './BetslipSystemSelector'
import { BetslipAccountSelector } from './BetslipAccountSelector'
import { BetSlipStakeSelector } from './BetslipStakeSelector'
import { BetslipValues } from './BetslipValues'
import { BetslipWarnings } from './BetslipWarnings'
import { BetTypeSelector } from './BetslipTypeSelector'
import { formatMoney } from '../../../util/number'
import useUserCurrency from '../../../hooks/useUserCurrency'
import BetslipTokenBonusSelector from './BetslipTokenBonusSelector'
import { copyTextToClipboard } from '../../../util/clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FadeIn from '../../animation/FadeIn'
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const BetslipButton = ({
  title = null,
  subtitle = null,
  onClick = null,
  children = null,
  disabled = false,
  backgroundColor = 'bg-secondary',
  textColor = 'text-secondaryContrast',
  collapsed,
}) => {
  return (
    <ContainedButton
      onClick={onClick}
      disabled={disabled}
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor="border-none"
      className="betslip-button h-[52px]"
      rounded={!collapsed}
      fullWidth
    >
      {title && (
        <div className="betslip-button-inner flex flex-col justify-center items-center">
          {subtitle && <span className="betslip-button-subtitle text-xxs font-normal">{subtitle}</span>}
          <span className="betslip-button-title text-lg font-semibold leading-5">{title}</span>
        </div>
      )}
      {children}
    </ContainedButton>
  )
}

const PlaceBetSuccessful = ({ onGoToMyBets, onNewBetslip, collapsed }) => {
  const { t } = useTranslation('bets')

  useEffect(() => {
    const timeout = setTimeout(onNewBetslip, 5000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return <BetslipButton title={t('BetSlip.goToMyBets')} onClick={onGoToMyBets} collapsed={collapsed} />
}

const SignInToBet = ({ collapsed }) => {
  const router = useRouter()
  const { t } = useTranslation('bets')
  const redirectTo = router?.asPath

  return (
    <Link href={'/account/login?redirectTo=' + redirectTo} className="betslip-signin-to-bet w-full">
      <BetslipButton title={t('BetSlip.signInToBet')} collapsed={collapsed} />
    </Link>
  )
}

const PlacingBet = ({ collapsed }) => {
  const shouldReduceMotion = useReducedMotion()
  const betslipContext = useBetslipContext()
  const [liveDelay, setLiveDelay] = useState(null)

  useEffect(() => {
    setLiveDelay(Math.round(betslipContext.placeBetLiveDelay))
    let interval = null
    if (betslipContext.placeBetLiveDelay != null && betslipContext.placeBetLiveDelay > 0) {
      interval = setInterval(() => setLiveDelay((delay) => Math.round(delay - 1)), 1000)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [betslipContext.placeBetLiveDelay])

  return (
    <motion.div
      variants={!shouldReduceMotion ? FadeInOut : null}
      initial="initial"
      animate="animate"
      exit="exit"
      className={`betslip-placing-bet absolute top-0 left-0 flex ${
        collapsed ? 'betslip-placing-bet-collapsed flex-row space-x-2' : 'flex-col space-y-4'
      } justify-center items-center w-full h-full bg-gray-800 bg-opacity-80 z-10`}
    >
      <div className="relative">
        <ClipLoader color="white" size={collapsed ? 60 : 80} />

        {liveDelay != null && liveDelay >= 1 && (
          <span className="betslip-placing-bet-delay absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl font-semibold text-white">
            {liveDelay}
          </span>
        )}
      </div>

      <div
        className={`betslip-placing-bet-text flex flex-col text-lg text-white ${
          collapsed ? 'text-left' : 'text-center'
        }`}
      >
        <Trans i18nKey="bets:BetSlip.betDelayDescription" components={[<span />, <span className="font-bold" />]} />
      </div>
    </motion.div>
  )
}

const PlaceBet = ({ betslipState, onPlaceBet, collapsed }) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const placeBetPossible = betslipContext.placeBetPossible() && betslipContext.betslipState === BetslipState.Initial
  const isFreeBet = betslipContext.account?.type === AccountType.FreeBet

  return (
    <BetslipButton
      title={isFreeBet ? t('BetSlip.placeFreeBet') : t('BetSlip.placeBet')}
      onClick={betslipState !== BetslipState.PlacingBet ? onPlaceBet : null}
      disabled={!placeBetPossible}
      collapsed={collapsed}
    />
  )
}

const ShareBet = ({ betslipState, onShareBet }) => {
  const betslipContext = useBetslipContext()
  const placeBetPossible = betslipContext.placeBetPossible() && betslipContext.betslipState === BetslipState.Initial

  return (
    <IconButton
      title={'Share betslip'}
      onClick={betslipState !== BetslipState.PlacingBet ? onShareBet : null}
      disabled={!placeBetPossible}
      icon={faArrowUpFromBracket}
      rounded={true}
      textColor="text-betslipContrast"
      backgroundColor="bg-gray-500 bg-opacity-10"
      borderColor="border-transparent"
      className="mr-2"
    />
  )
}

const BalanceTooLow = ({ collapsed }) => {
  const { t } = useTranslation('bets')

  return (
    <Link href={'/payment/deposit'} className="betslip-balance-too-low w-full">
      <BetslipButton
        title={t('BetSlip.balanceTooLowTitle')}
        subtitle={t('BetSlip.balanceTooLowSubtitle')}
        collapsed={collapsed}
      />
    </Link>
  )
}

const AcceptOddsChanges = ({ mustAcceptOddsChanges, mustAcceptAvailabilityChanges, collapsed }) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()

  let subTitle = null
  if (mustAcceptOddsChanges && !mustAcceptAvailabilityChanges) {
    subTitle = t('BetSlip.acceptOddsChangesSubtitle')
  } else if (!mustAcceptOddsChanges && mustAcceptAvailabilityChanges) {
    subTitle = t('BetSlip.acceptAvailabilityChangesSubtitle')
  } else if (mustAcceptOddsChanges && mustAcceptAvailabilityChanges) {
    subTitle = t('BetSlip.acceptOddsAndAvailabilityChangesSubtitle')
  }

  return (
    <BetslipButton
      title={t('BetSlip.acceptOddsChangesTitle')}
      subtitle={subTitle}
      onClick={betslipContext.acceptChanges}
      backgroundColor="bg-live"
      textColor="text-liveContrast"
      collapsed={collapsed}
    />
  )
}

type BetslipBottomAreaProps = {
  onPlaceBet: () => Promise<void>
  onShareBet: () => void
  onNewBetslip: () => void
  closeBetslip: () => void
  betslipState: BetslipState
  collapsed: boolean
}

const BetslipFooter = ({
  onPlaceBet,
  onShareBet,
  onNewBetslip,
  betslipState,
  closeBetslip,
  collapsed,
}: BetslipBottomAreaProps) => {
  const { user } = useUser()
  const { stake, account, mustAcceptChanges } = useBetslipContext()
  const router = useRouter()
  const sportsBettingSettings = useSportsBettingSettings()

  const _mustAcceptChanges = mustAcceptChanges()

  const onGoToMyBets = useCallback(async () => {
    await router.push('/account/bets')
    closeBetslip()
  }, [])

  return (
    <div className={`betslip-footer flex justify-center w-full transition-all ${collapsed ? '' : 'px-2 pb-3 md:px-3'}`}>
      {isAnonymousUser(user) && <SignInToBet collapsed={collapsed} />}

      {isLoggedInCustomer(user) && (
        <>
          {account?.type == AccountType.Main &&
          account?.balance < stake &&
          sportsBettingSettings.showDepositOptionInBetSlip ? (
            <BalanceTooLow collapsed={collapsed} />
          ) : (
            <>
              {betslipState === BetslipState.PlaceBetSuccessful && (
                <PlaceBetSuccessful onGoToMyBets={onGoToMyBets} onNewBetslip={onNewBetslip} collapsed={collapsed} />
              )}

              <AnimatePresence>
                {betslipState === BetslipState.PlacingBet && <PlacingBet collapsed={collapsed} />}
              </AnimatePresence>

              {(_mustAcceptChanges.mustAcceptAvailabilityChanges || _mustAcceptChanges.mustAcceptOddsChanges) &&
              betslipState === BetslipState.Initial ? (
                <div className="betslip-footer-accept-changes flex items-center w-full">
                  <AcceptOddsChanges
                    mustAcceptAvailabilityChanges={_mustAcceptChanges.mustAcceptAvailabilityChanges}
                    mustAcceptOddsChanges={_mustAcceptChanges.mustAcceptOddsChanges}
                    collapsed={collapsed}
                  />
                </div>
              ) : (
                <>
                  {(betslipState === BetslipState.Initial || betslipState === BetslipState.PlacingBet) && (
                    <div className="betslip-footer-place-bet flex items-center w-full">
                      {collapsed && (
                        <BetSlipStakeSelector
                          disabled={betslipState === BetslipState.PlacingBet}
                          wrapperClassName={'h-full !rounded-md'}
                          inputClassName={'!text-sm !text-left pt-2'}
                        />
                      )}
                      {!collapsed && sportsBettingSettings.betSharingEnabled && (
                        <ShareBet betslipState={betslipState} onShareBet={onShareBet} />
                      )}
                      <PlaceBet betslipState={betslipState} onPlaceBet={onPlaceBet} collapsed={collapsed} />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

type BetslipHeaderProps = {
  closeBetslip: () => void
  clearBetslip: () => void
  collapsed: boolean
  toggleCollapsed: () => void
}

const BetslipHeader = ({ closeBetslip, clearBetslip, collapsed, toggleCollapsed = null }: BetslipHeaderProps) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const currency = useUserCurrency()

  return (
    <div className="betslip-header flex flex-col flex-shrink-0 bg-betslipHeader text-betslipHeaderContrast">
      <div className="betslip-header-inner flex justify-between items-center px-2 h-[52px] border-b-[1px] border-neutral">
        <div className="betslip-header-inner-clear flex justify-center items-center h-full aspect-square">
          <IconButton
            title={'Clear betslip'}
            onClick={clearBetslip}
            size="sm"
            icon={faTrashAlt}
            backgroundColor="bg-none"
            borderColor="border-none"
            textColor="text-inherit"
          />
        </div>

        <div
          className="betslip-header-inner-center flex flex-grow space-x-2 items-center h-full justify-center cursor-pointer"
          onClick={closeBetslip ?? toggleCollapsed}
        >
          <span className="betslip-header-inner-center-label text-lg font-semibold">
            {!collapsed || betslipContext.maxReturn <= 0
              ? t('BetSlip.betSlip')
              : t('BetSlip.win') + ' ' + formatMoney(betslipContext.maxReturn, currency)}
          </span>

          {betslipContext.selectedOdds.length > 0 && !collapsed && (
            <Tag
              className="betslip-header-inner-center-tag !h-5"
              backgroundColor="bg-betslipHeaderContrast"
              textColor="text-betslipHeader"
              label={
                betslipContext.betType === BetTypes.Combi
                  ? t('BetsPage.combiOf', { combination: betslipContext.selectedOdds.length })
                  : t('BetsPage.nSelections', { amount: betslipContext.selectedOdds.length })
              }
            />
          )}
        </div>

        <div className="betslip-header-inner-close flex justify-center items-center h-full aspect-square">
          {(closeBetslip != null || toggleCollapsed != null) && (
            <IconButton
              title={'Close betslip'}
              onClick={closeBetslip ?? toggleCollapsed}
              size="sm"
              icon={collapsed ? faChevronUp : faChevronDown}
              backgroundColor="bg-none"
              borderColor="border-none"
              textColor="text-inherit"
            />
          )}
        </div>
      </div>
      {!collapsed && (
        <>
          <BetTypeSelector />
          <AlertContainer alertContainerId="betslip" />
        </>
      )}
    </div>
  )
}

const BetslipSuccess = ({ collapsed }) => {
  const options = {
    loop: false,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className="betslip-success absolute top-0 left-0 w-full h-full py-4 flex flex-col space-y-2 justify-center items-center bg-betslip text-sm !mt-0">
      {!collapsed && <Lottie options={options} width={collapsed ? 50 : 100} height={collapsed ? 50 : 100} />}
      <Trans i18nKey="bets:BetSlip.successText" components={[<span className="betslip-success-text font-bold" />]} />
    </div>
  )
}

const BetslipShareOverlayButton = ({ icon, text, buttonClassName = null, onClick = null }) => {
  return (
    <div className="betslip-share-overlay-item flex items-center space-x-4">
      <ContainedButton
        fullWidth
        rounded
        onClick={onClick}
        className={`betslip-share-overlay-item-button !justify-between ${buttonClassName}`}
      >
        <div className="betslip-share-overlay-item-button-icon flex justify-center w-4">
          <FontAwesomeIcon className="text-xl" icon={icon} />
        </div>
        <span className="betslip-share-overlay-item-button-label">{text}</span>
        <div className="w-4"></div>
      </ContainedButton>
    </div>
  )
}

const BetslipShareOverlay = ({ mode, shareData, closeOverlay }) => {
  const { t } = useTranslation()

  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)

  const onCopyLinkClick = async () => {
    const clipboardText = shareData.url
    copyTextToClipboard(clipboardText, t)
    setCopiedToClipboard(true)
  }

  const onShareViaWhatsapp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareData.text + '\r\n' + shareData.url)}`
    window.open(url, '_blank')
    closeOverlay()
  }

  const onShareViaTelegram = () => {
    const url = `https://telegram.me/share/url?url=${encodeURIComponent(shareData.url)}&text=${encodeURIComponent(
      shareData.text,
    )}`
    window.open(url, '_blank')
    closeOverlay()
  }

  const onShareClick = async () => {
    await navigator.share(shareData)
  }

  const onCloseClick = () => {
    closeOverlay()
  }

  return (
    <div className="betslip-share-overlay flex justify-center items-center absolute top-[52px] left-0 h-[calc(100%-52px)] w-full text-white bg-black bg-opacity-50 backdrop-blur-md">
      <div
        className="betslip-share-overlay-close flex justify-center items-center absolute right-0 top-0 h-16 w-16 cursor-pointer"
        onClick={onCloseClick}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="betslip-share-overlay-inner flex flex-col items-center px-8 w-full">
        {/* <FontAwesomeIcon className="text-3xl text-primary" icon={faShareFromSquare} /> */}
        <span className="betslip-share-overlay-title mt-4 mb-8 text-2xl">
          {t('bets:BetSlip.shareBetOverlay.title')}
        </span>
        <div className="betslip-share-overlay-items flex flex-col space-y-4 w-full">
          <BetslipShareOverlayButton
            text={t('bets:BetSlip.shareBetOverlay.copyLink')}
            icon={faLink}
            onClick={onCopyLinkClick}
            buttonClassName={'!bg-gray-500 text-white'}
          />
          <BetslipShareOverlayButton
            text={t('bets:BetSlip.shareBetOverlay.whatsapp')}
            icon={faWhatsapp}
            onClick={onShareViaWhatsapp}
            buttonClassName={'!bg-[#25D366] text-white'}
          />
          <BetslipShareOverlayButton
            text={t('bets:BetSlip.shareBetOverlay.telegram')}
            icon={faTelegram}
            onClick={onShareViaTelegram}
            buttonClassName={'!bg-[#2AABEE] text-white'}
          />
          {navigator?.canShare && (
            <BetslipShareOverlayButton
              text={t('bets:BetSlip.shareBetOverlay.share')}
              icon={faArrowUpFromBracket}
              onClick={onShareClick}
              buttonClassName={'!bg-gray-500 text-white'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const BetslipInner = ({ closeBetslip, mode, dropShadow = false, collapsed = null, toggleCollapsed = null }) => {
  const { user } = useUser()
  const betslipContext = useBetslipContext()

  const onPlaceBet = async () => {
    await betslipContext.placeBetSlip()
  }

  const onShareBet = async () => {
    await betslipContext.shareBetSlip()
  }

  const onSuccessNewBetslip = useCallback(() => {
    betslipContext.clearBetSlip()
  }, [])

  const onClearBetslip = useCallback(() => {
    if (betslipContext.betslipState === BetslipState.PlacingBet) return
    betslipContext.clearBetSlip()
  }, [betslipContext.betslipState])

  const onRemoveOdd = async (oddId: number) => {
    if (betslipContext.betslipState === BetslipState.PlacingBet) return
    await betslipContext.removeOdd(oddId)
  }

  const onCloseBetslip = useCallback(() => {
    if (betslipContext.betslipState === BetslipState.PlacingBet) return
    if (closeBetslip != null) closeBetslip()
  }, [betslipContext.betslipState])

  return (
    <div
      className={`betslip-inner flex flex-col flex-grow md:max-w-[500px] bg-betslip text-betslipContrast overflow-hidden relative pointer-events-auto ${
        mode === 'desktop'
          ? 'betslip-inner-desktop max-h-[75vh]'
          : 'betslip-inner-mobile max-h-[calc(var(--doc-height)-52px)]'
      } ${dropShadow ? 'shadow-lg' : ''} ${
        mode === 'desktop' ? 'border-[1px] border-neutral rounded-t-md' : 'md:rounded-t-md'
      }`}
    >
      <BetslipHeader
        closeBetslip={closeBetslip ? onCloseBetslip : null}
        clearBetslip={onClearBetslip}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
      />

      <div
        className={`betslip-inner-content-wrapper flex flex-grow flex-col w-full overflow-hidden ${
          !collapsed ? 'space-y-4' : ''
        }`}
      >
        <div
          className={`betslip-inner-content flex flex-grow flex-col overflow-hidden relative ${
            !collapsed ? 'space-y-2' : ''
          }`}
        >
          {isLoggedInCustomer(user) && <BetslipWarnings betslipState={betslipContext.betslipState} />}

          <BetslipItems
            removeOdd={onRemoveOdd}
            scrollToLastBetslipItem={mode === 'desktop'}
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />

          <AnimatePresence>
            {!collapsed && (
              <motion.div
                variants={Accordion}
                initial="initial"
                animate="animate"
                exit="exit"
                className="betslip-inner-content-controls flex flex-col shrink-0 space-y-2 px-2 md:px-3"
              >
                {betslipContext.validTokenBonuses()?.length > 0 && <BetslipTokenBonusSelector />}

                {betslipContext.betType === BetTypes.System && betslipContext.betSystems?.length > 0 && (
                  <BetSlipSystemSelector />
                )}

                {betslipContext.selectedOdds?.length > 0 && (
                  <>
                    <div className="betslip-inner-content-controls-account-and-stake grid grid-cols-2 gap-4">
                      <BetslipAccountSelector />
                      <BetSlipStakeSelector
                        disabled={
                          betslipContext.betslipState === BetslipState.PlacingBet ||
                          betslipContext.selectedTokenBonus?.bonusType === BonusType.FreeBetToken
                        }
                      />
                    </div>

                    <BetslipValues />
                  </>
                )}
              </motion.div>
            )}
          </AnimatePresence>

          {betslipContext.betslipState === BetslipState.PlaceBetSuccessful && <BetslipSuccess collapsed={collapsed} />}
        </div>

        <BetslipFooter
          onPlaceBet={onPlaceBet}
          onShareBet={onShareBet}
          onNewBetslip={onSuccessNewBetslip}
          betslipState={betslipContext.betslipState}
          closeBetslip={closeBetslip}
          collapsed={collapsed}
        />
      </div>

      <AnimatePresence>
        {betslipContext.shareData && (
          <FadeIn>
            <BetslipShareOverlay
              mode={mode}
              shareData={betslipContext.shareData}
              closeOverlay={betslipContext.clearShareData}
            />
          </FadeIn>
        )}
      </AnimatePresence>
    </div>
  )
}

export type BetslipProps = {
  closeBetslip?: () => void
  mode?: BetslipViewMode
  dropShadow?: boolean
}

export type BetslipViewMode = 'desktop' | 'mobile'

const Betslip = ({ mode = 'mobile', closeBetslip = null, dropShadow = false }: BetslipProps) => {
  const betslipContext = useBetslipContext()
  const shouldReduceMotion = useReducedMotion()

  const [desktopCollapsed, setDesktopCollapsed] = useState<boolean>(true)
  const [lastSelectedOddsLength, setLastSelectedOddsLength] = useState<number>(0)

  useEffect(() => {
    if (mode === 'mobile') {
      setBodyNotScrollable()
      return () => {
        setBodyScrollable()
      }
    }
  }, [])

  useEffect(() => {
    if (mode === 'desktop') {
      if (betslipContext.selectedOdds?.length > 0 && lastSelectedOddsLength === 0) {
        setDesktopCollapsed(false)
      } else if (betslipContext.selectedOdds?.length === 0 && lastSelectedOddsLength > 0) {
        setDesktopCollapsed(true)
      }
      setLastSelectedOddsLength(betslipContext.selectedOdds?.length)
    }
  }, [betslipContext.selectedOdds?.length])

  const onCloseBetslip = () => {
    if (betslipContext.betslipState === BetslipState.PlacingBet) return
    if (closeBetslip != null) closeBetslip()
    if (betslipContext.betslipState === BetslipState.PlaceBetSuccessful) {
      betslipContext.clearBetSlip()
    }
    betslipContext.clearShareData()
  }

  const onToggleCollapsed = useCallback(() => {
    setDesktopCollapsed(!desktopCollapsed)
    betslipContext.clearShareData()
  }, [desktopCollapsed])

  return (
    <>
      {mode === 'mobile' && (
        <OverlayContainer onBackgroundClick={onCloseBetslip}>
          <motion.div
            variants={!shouldReduceMotion ? BetslipAnimation : null}
            initial="initial"
            animate="show"
            exit="hide"
            className="betslip-mobile flex justify-center fixed bottom-[calc(52px+var(--safe-area-inset-bottom))] max-h-[calc(var(--doc-height)-52px-var(--safe-area-inset-bottom))] w-full max-w-appMaxWidth z-[25] rounded-t-md pointer-events-none"
          >
            <BetslipInner mode="overlay" closeBetslip={onCloseBetslip} />
          </motion.div>
        </OverlayContainer>
      )}

      {mode === 'desktop' && (
        <motion.div className="betslip-desktop fixed bottom-0 -ml-2 w-[384px] shadow-card z-[25]">
          <BetslipInner
            mode="desktop"
            closeBetslip={closeBetslip}
            dropShadow={dropShadow}
            collapsed={desktopCollapsed}
            toggleCollapsed={onToggleCollapsed}
          />
        </motion.div>
      )}
    </>
  )
}

export default Betslip
