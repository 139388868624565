import { BetSlipItemType } from '@arland-bmnext/api-data'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import { useBetslipContext } from '../../../context/betslip.context'
import { betSystemRowCounts } from '../../../services/bet/bet.constants'
import HelpText from '../../HelpText'

export const BetSlipSystemSelector = ({}) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()

  const betslip = betslipContext.betslips[0]

  const bankerCount = betslipContext.selectedOdds?.filter((x) => x.type === BetSlipItemType.Banker)?.length ?? 0
  const tipCount = betslip?.itemCount ?? 0
  const combiOf = betslip?.systems[0]?.combination
  const betCount = tipCount > 0 && combiOf > 0 ? betSystemRowCounts[tipCount - 1][combiOf - 1] : 0

  const onChange = (event) => {
    betslipContext.toggleBetSystem(event.target.value)
  }

  return (
    <div className="betslip-system-selector grid grid-cols-2 gap-4 w-full h-[42px] items-center">
      <div className="betslip-system-selector-info flex flex-col">
        <div className="betslip-system-selector-info-helptext flex items-center space-x-1 whitespace-nowrap overflow-hidden">
          <HelpText
            title={t('BetSlip.betSystemHelpText.title')}
            description={t('BetSlip.betSystemHelpText.description')}
            displayIcon={false}
          >
            <span className="betslip-system-selector-info-helptext-title text-xxs font-bold text-primary underline overflow-ellipsis">
              {t('BetSlip.betSystemHelpText.title')}
            </span>
          </HelpText>
        </div>
        
        <span className="betslip-system-selector-info-details text-xs font-bold whitespace-nowrap overflow-hidden overflow-ellipsis">
          {t('BetSlip.betSystemInfo.bankerCount', { bankerCount })} |{' '}
          {t('BetSlip.betSystemInfo.tipCount', { tipCount })} | {t('BetSlip.betSystemInfo.betCount', { betCount })}
        </span>
      </div>

      <div className="betslip-system-selector-action flex relative w-full h-full px-2 rounded-sm bg-gray-500 bg-opacity-10">
        <span className="betslip-system-selector-action-title absolute top-1 left-2 text-xxs opacity-60 font-semibold">
          {t('BetSlip.betSystemSelectorTitle')}
        </span>

        <select
          className="betslip-system-selector-action-select flex w-full bg-transparent text-xs font-bold overflow-hidden overflow-ellipsis whitespace-nowrap rounded-sm h-[42px] pr-8 pt-[16px] pb-[4px] focus:outline-none appearance-none cursor-pointer"
          onChange={onChange}
          value={betslipContext.selectedBetSystem}
        >
          {betslipContext.betSystems?.map((betSystem) => (
            <option value={betSystem.identifier} key={betSystem.identifier} className="text-black">
              {t('BetSlip.betSystemSelectorOption', {
                combi: betSystem.combi,
                tipCount: tipCount,
                betAmount: betSystem.betAmount,
              })}
            </option>
          ))}
        </select>

        <FontAwesomeIcon
          icon={faChevronDown}
          className="betslip-system-selector-action-indicator absolute right-3 bottom-[13px] opacity-60 pointer-events-none"
        />
      </div>
    </div>
  )
}
