import { motion } from 'framer-motion'
import React from 'react'
import { FadeIn as FadeInAnimation } from '../../animations/common'

const FadeIn = ({ children, className = '' }) => {
  return (
    <motion.div variants={FadeInAnimation} initial="initial" animate="animate" className={'fade-in ' + className}>
      {children}
    </motion.div>
  )
}

export default FadeIn
